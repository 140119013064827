<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" :model="searchform" ref="searchform">
        <el-form-item label="设备租赁号:" prop="id">
          <el-input
            style="width: 180px"
            clearable
            v-model="box_name"
            placeholder="请输入设备租赁号"
          ></el-input>
        </el-form-item>

        <el-form-item label="还款周期:" prop="id">
          <el-input
            style="width: 90px"
            clearable
            v-model="num_star"
            placeholder="请输入"
          ></el-input>
          --
          <el-input
            style="width: 90px"
            clearable
            v-model="num_end"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="member">
          <el-select v-model="sta" placeholder="请选择" style="width: 150px">
            <el-option label="全部状态" value=""></el-option>
            <el-option label="待审核" value="1"></el-option>
            <el-option label="审核通过" value="2"></el-option>
            <el-option label="审核拒绝" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间" prop="time">
          <el-date-picker
            v-model="time"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="searchinfo"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="box_name" label="设备租赁号" align="center">
      </el-table-column>
      <el-table-column prop="price" label="还款金额" align="center">
      </el-table-column>
      <el-table-column prop="created_at" label="还款时间" align="center">
      </el-table-column>
      <el-table-column label="还款周期" align="center">
        <template slot-scope="scope">
          <el-link @click="showFen(scope.row)">{{ scope.row.num }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="need_price" label="待付总金额" align="center">
      </el-table-column>
      <el-table-column prop="all_price" label="已付总金额" align="center">
      </el-table-column>
      <el-table-column label="审核状态" align="center">
        <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.list_sta == 2"
            >已通过</el-link
          >
          <el-link type="danger" v-if="scope.row.list_sta == 3">未通过</el-link>
          <el-link type="primary" v-if="scope.row.list_sta == 1"
            >待审核</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="360">
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.list_sta == 1"
            @click="set(scope.row)"
            style="margin-left: 10px"
            >审核</el-link
          >
          <el-link
            type="success"
            v-if="scope.row.list_sta == 2"
            style="margin-left: 10px"
            >已通过</el-link
          >
          <el-link
            type="danger"
            v-if="scope.row.list_sta == 3"
            style="margin-left: 10px"
            >未通过</el-link
          >
        </template>
      </el-table-column>
    </page-table>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form label-width="auto">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="是否允许该条信息审核通过" prop="role_name">
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refuse">拒绝</el-button>
        <el-button type="primary" @click="submitForm">通过</el-button>
      </div>
    </el-dialog>
    <show-fen ref="showFen" />
  </div>
</template>

<script>
import { reletStalist, reletSta } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import { number } from "mathjs";
import showFen from "./components/showFen.vue";

export default {
  name: "user",
  components: {
    pageTable,
    showFen,
  },
  data() {
    return {
      dialogVisible: false,
      box_name: "",
      id: "",
      time: "",
      sta: "",
      num_star: "",
      num_end: "",
      userList: [], // 列表
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
      searchform: {
        num_star: "",
        num_end: "",
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },

  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    submitForm() {
      let params = {
        token: sessionStorage.getItem("token"),
        sta: 2,
        id: this.id,
      };
      reletSta(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.getUserList();
        }
      });
    },

    refuse() {
      let params = {
        token: sessionStorage.getItem("token"),
        sta: 3,
        id: this.id,
      };
      reletSta(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.getUserList();
        }
      });
    },
    showFen(row) {
      this.$refs.showFen.show(JSON.parse(JSON.stringify(row)));
    },

    close() {
      this.dialogVisible = false;
    },

    set(row) {
      this.dialogVisible = true;
      this.id = row.id;
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    searchinfo() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: 1,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        box_name: this.box_name,
        num_star: this.num_star,
        num_end: this.num_end,
        list_sta: this.sta,
        created_at_one: this.time[0],
        created_at_two: this.time[1],
      };
      reletStalist(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },

    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
      };
      reletStalist(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    upzhouqi(row) {
      console.log("123");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
